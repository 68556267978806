import { useChatbotTheme } from 'hooks/auth';

export const CustomOptions = ({
  previousStep,
  step,
  triggerNextStep,
  steps,
  customValue = null,
}) => {
  const chatbotTheme = useChatbotTheme();
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <div
        key={Math.random() * 1000}
        style={{
          backgroundColor: chatbotTheme.botBubbleColor,
          color: '#000',
          borderRadius: '10px 10px 10px 0px',
          fontFamily: chatbotTheme.fontFamily,
          fontSize: 14,
          padding: 12,
          marginBottom: 10,
          maxWidth: '70%',
          minWidth: '5%',
        }}
      >
        {'Here are some follow up questions you can ask:'}
      </div>
      {(customValue ? customValue : previousStep.value).map((option, index) => {
        return (
          <div
            key={index}
            style={{
              backgroundColor: chatbotTheme.background,
              textAlign: 'center',
              color: chatbotTheme.headerBgColor,
              borderRadius: '10px 10px 10px 10px',
              borderStyle: 'solid',
              borderWidth: '2px',
              borderColor: chatbotTheme.headerBgColor,
              fontFamily: chatbotTheme.fontFamily,
              fontSize: 13,
              padding: 12,
              marginBottom: 10,
              cursor: 'pointer',
              maxWidth: '70%',
              minWidth: '5%',
            }}
            onClick={() => {
              triggerNextStep({ value: option, trigger: '1.1.4' });
            }}
          >
            {option}
          </div>
        );
      })}
      <div
        key={Math.random() * 1000}
        style={{
          backgroundColor: '#b3b3b340',
          color: '#000',
          textAlign: 'left',
          borderRadius: '10px 10px 10px 10px',
          fontFamily: chatbotTheme.fontFamily,
          fontSize: 14,
          padding: 12,
          marginBottom: 0,
          cursor: 'pointer',
          maxWidth: '70%',
          minWidth: '5%',
        }}
        onClick={() => {
          triggerNextStep({ trigger: '1.1' });
        }}
      >
        {'Click here to ask a different question?'}
      </div>
    </div>
  );
};
