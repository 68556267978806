import { CustomMessage } from './CustomMessage';

export const CustomSelected = ({
  previousStep,
  step,
  triggerNextStep,
  steps,
}) => {
  step.value = previousStep.value;
  return (
    <CustomMessage
      previousStep={previousStep}
      steps={steps}
      triggerNextStep={triggerNextStep}
    />
  );
};
